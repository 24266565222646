import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

const FaqItem = ({ faqTitle, faqBody }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const [ref, inView] = useInView({
    rootMargin: "-100px",
  });
  return (
    <div className="faq-item" ref={ref}>
      <div
        onClick={() => setAccordionOpen(!accordionOpen)}
        className={`faq-title ${accordionOpen ? "faq-open-title" : ""}`}
      >
        <h2>{faqTitle}</h2>
        <button className="faq-item-button" aria-label="faq-button">
          {inView && <img src="/images/icons/arrow-right.svg" alt="faq-item-button" width={10} height={6} />}
        </button>
      </div>
      <div className={`faq-body ${accordionOpen ? "faq-open" : ""}`}>
        <div className="card-text" dangerouslySetInnerHTML={{ __html: faqBody?.childMarkdownRemark?.html }} />
        <div className="card-text">{faqBody?.faqAnswer}</div>
      </div>
    </div>
  );
};

const FaqComponent = ({ faqItems, title }) => {
  return (
    <div>
      <div className="wp-block wp-block--heading faq-component-title">
        <h2 className="mt-5">{title}</h2>
      </div>
      <div className="wp-block wp-block--rank-mathfaq-block">
        {faqItems.length &&
          faqItems.map((faqItem) => {
            return (
              <FaqItem
                className="rank-math-list-item"
                faqTitle={faqItem?.faqQuestion}
                faqBody={faqItem?.faqAnswer}
                key={faqItem?.faqQuestion}
              />
            );
          })}
      </div>
    </div>
  );
};

export default FaqComponent;
